jQuery(document).ready(function($){

	$('#ccc-notify-accept').click(function(){
		console.log('Child click');
		location.reload();
	});

	$mainLinks = $('.site-header .primary-navigation>div>ul');
	$mainLinksWithChildren = $('.site-header .primary-navigation>div>ul>li.menu-item-has-children')
	$mainLinksWithChildrenLinks = $('.site-header .primary-navigation>div>ul>li.menu-item-has-children>a')
	$svgForDropdownLinksString = '<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.5938 0.578125L12 1.98438L6 7.98438L0 1.98438L1.40625 0.578125L6 5.17188L10.5938 0.578125Z" fill="#5a1a31"/></svg>';
	$svgForSubItems = '<svg class="mobile-icon" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4141 8.9375H0V7.0625H11.4141L6.16406 1.8125L7.5 0.5L15 8L7.5 15.5L6.16406 14.1875L11.4141 8.9375Z" fill="#5a1a31"/></svg>';
	$imageNav = $('.site-header').hasClass('image-nav');



	//Adjusts structure of dropdown nav
	$mainLinksWithChildren.each(function(){
		$title = "<h4 class='submenu-title d-flex flex-row align-items-center'><a href='" + $(this).children('a').eq(0).attr('href') + "'>" +  $(this).children('a').eq(0).html() + $svgForSubItems + "</a></h4>";
		$closebuttonContent = '<div class="d-lg-none mobile-content"><strong>Main Menu</strong></div>'
		if($imageNav){
			$(this).append("<div class='dropdown-wrapper'><div class='container'><div class='row title-row'><div class='col-xl-10 offset-xl-1 justify-content-lg-center'></div></div><div class='row menu-row'><div class='col-xl-10 offset-xl-1'></div></div></div>");
		}else{
			$(this).append("<div class='dropdown-wrapper'><div class='container'><div class='row title-row'><div class='col-xl-10 offset-xl-1 col-lg-6 offset-lg-3  justify-content-lg-center'></div></div><div class='row menu-row'><div class='col-xl-10 offset-xl-1 col-lg-6 offset-lg-3'></div></div></div>");
		}
		// $(this).children('.dropdown-menu').addClass('col-lg-12 col-xl-10 offset-xl-1');
		$(this).find('.dropdown-wrapper .menu-row>div').prepend($(this).children('.dropdown-menu'));
		$(this).find('.dropdown-wrapper .title-row>div').prepend($title);
		$(this).find('.dropdown-wrapper .title-row>div').append('<div class="close-button flex-row"></div>');
		$(this).find('.close-button').append($svgForSubItems);
		$(this).find('.close-button').append($closebuttonContent);
		$(this).find('.dropdown-wrapper .title-row>div>.submenu-title>svg').on('click', function(){
			window.location.href = $(this).siblings('a').attr('href');
		})
	})


	//Open dropdown menu
	$mainLinksWithChildrenLinks.each(function(){
		$(this).append($svgForDropdownLinksString);
		$(this).append($svgForSubItems);
		$(this).on('click', function(event){
			event.preventDefault();
			event.stopPropagation();
			if(window.innerWidth < 1350){
				$('.site-header .primary-navigation>div').addClass('slid');
				$mainLinksWithChildren.each(function(){
					$(this).removeClass('menu-active')
				})
				$dropdownLinks.each(function(){
					$(this).parent().removeClass('menu-active');
				})
				$(this).parent().addClass('menu-active');
				$mainLinks.addClass('submenu-active');
			}else{
				if($(this).parent().hasClass('menu-active')){
					$(this).parent().removeClass('menu-active');
					$('.site-header').removeClass('menu-open');
					$('body').removeClass('menu-open');
					$(this).siblings('.dropdown-wrapper').attr('style', '');
					$(this).siblings('.dropdown-wrapper').find('.container').attr('style', '');
				}else{
					if($(this).parent().siblings().hasClass('menu-active')){
						$(this).siblings('.dropdown-wrapper').css({'transition':'0s', 'transition-delay':'0.4s'});
						$(this).siblings('.dropdown-wrapper').find('.container').css('transition-delay','0.5s');
						$($(this).parent().siblings('.menu-active')[0]).find('.dropdown-wrapper').css({'transition':'0', 'transition-delay': '0.5s'});
						$($(this).parent().siblings('.menu-active')[0]).find('.container').css('transition-delay', '0s');
						console.log($($(this).parent().siblings('.menu-active')[0]).find('.container'));
					}
					$mainLinksWithChildren.each(function(){
						$(this).removeClass('menu-active')
					})
					$dropdownLinks.each(function(){
						$(this).parent().removeClass('menu-active');
					})
					$(this).parent().addClass('menu-active');
					$('.site-header').addClass('menu-open');
					$('body').addClass('menu-open');
					$mainLinks.addClass('submenu-active');
				}
			}
			
			
		})
	})

	//Open next level of dropdown menu
	$dropdownLinks = $('.site-header .primary-navigation>div>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li.menu-item-has-children>a');
	$dropdownSVGIcon = '<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.40625 0L7.40625 6L1.40625 12L0 10.5938L4.59375 6L0 1.40625L1.40625 0Z" fill="black"/></svg>'
	// $dropdownLinks = $('.site-header .primary-navigation>div>ul>li.menu-item-has-children>.dropdown-wrapper');
	$dropdownLinks.each(function(){
		$(this).append($dropdownSVGIcon);
		$(this).on('click', function(event){
				event.preventDefault();
				
				if($(this).parent().hasClass('menu-active')){
					$dropdownLinks.each(function(){
						$(this).parents('.dropdown-wrapper').removeClass('submenu-active');
						$(this).parent().removeClass('menu-active');
					})
				}else{
					$dropdownLinks.each(function(){
						$(this).parent().removeClass('menu-active');
					})
					$(this).parent().addClass('menu-active');
					$(this).parents('.dropdown-wrapper').addClass('submenu-active');
				}
				
		})
	})

	//Adjust structure of second level of dropdown
	$dropdownMenuItems = $('.site-header .primary-navigation>div>ul>li.menu-item-has-children>.dropdown-wrapper>.container>.menu-row>div>ul>li.menu-item-has-children');
	$dropdownMenuItems.each(function(){
		$linkTitle = $(this).children('a').html();
		$linkURL = $(this).children('a').attr('href');
		$listItem = "<li class='parent-link current-page-parent menu-active' itemscope='itemscope' itemtype='https://www.schema.org/SiteNavigationElement'> <a href='" + $linkURL + "' class='dropdown-item'>" + $linkTitle + $svgForDropdownLinksString + "</a> </li>"
		$wrapper = "<div class='sub-dropdown-wrapper'></div>"
		$(this).append($wrapper);
		$(this).children('.sub-dropdown-wrapper').append($(this).children('.dropdown-menu'));
		if($linkURL != '#'){
			$(this).find('.sub-dropdown-wrapper .dropdown-menu').prepend($listItem);
		}

	})

	//Close menu


	$('body').on('click', function(){
		$dropdownLinks.each(function(){
			$(this).parent().removeClass('menu-active');
			$(this).parents('.dropdown-wrapper').removeClass('submenu-active');
		})
		$mainLinks.removeClass('submenu-active');

		$('.site-header').removeClass('menu-open');
		$('body').removeClass('menu-open');

		$mainLinksWithChildren.each(function(){
			if(window.innerWidth < 1350){
				$('.site-header .primary-navigation>div').removeClass('slid');
				$(this).removeClass('menu-active');
			}else{
				$(this).removeClass('menu-active');
				$(this).find('.dropdown-wrapper').attr('style', '');
				$(this).find('.dropdown-wrapper .container').attr('style', '');
			}
			
		})
	})
	// $('.main-nav').on('click', function(e){
	// 	e.stopPropagation();
	// })
	$('.dropdown-menu').on('click', function(e){
		e.stopPropagation();
	})


});